<template>
  <div class="col">
    <div class="row">
      <Table :fields="tableFields" :items="invoices" :busy="loading">
        <template #actions="item">
          <a target="_blank" :href="item.url">
            <span class="material-icons-outlined"> qr_code </span>
          </a>
        </template>
      </Table>
    </div>
    <div class="row">
      <Pagination
        v-model="currentPage"
        :total-pages="totalPages"
        @change="fetch()"
      ></Pagination>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Swal from 'sweetalert2';
import Table from '@/components/rebranding/table/Table.vue';
import Pagination from '@/components/rebranding/pagination/Pagination.vue';

export default {
  name: 'UsersCard',
  components: {
    Table,
    Pagination,
  },
  data() {
    return {
      loading: true,
      tableFields: [
        {
          key: 'id',
        },
        {
          key: 'total',
        },
        {
          key: 'status',
          localize: true,
        },
        {
          key: 'due_date',
        },
      ],
      departments: [],
      currentPage: 1,
      totalPages: 1,
      fullscreen: false,
      q: '',
      page: 1,
      pages: 1,
      users: [],
      editingUser: null,
      form: {},
      message: '',
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
  created() {
    this.fetch();
  },
  mounted() {
    this.$root.$on('UsersCardReload', () => {
      this.fetch();
    });
  },
  methods: {
    showModalInfo(item) {
      console.info('$emit:UsersModal', item);
      this.$root.$emit('UsersModal', item);
      this.$refs['users-modal'].showHandler('users-modal', item);
    },
    fetch() {
      this.loading = true;
      this.$store
        .dispatch('fetchCustomerInvoices', this.$route.params.customerId, {
          page: this.currentPage,
          q: this.q,
        })
        .then((response) => {
          this.invoices = response.data;
          this.totalPages = response.last_page;
          this.loading = false;
        });
    },
    updateUser() {
      this.updating = true;
      this.$store
        .dispatch('updateUser', this.editingUser)
        .then((response) => {
          this.fetch();
        })
        .finally(() => {
          this.updating = false;
        });
    },
    editUser(user) {
      this.editingUser = {
        id: user.id,
        email: user.email,
        department: user.departments.lenght ? user.departments[0].id : '',
        is_agent: user.pivot.is_agent,
        max_calls_at_time: user.pivot.max_calls_at_time,
        name: user.name,
        role: user.pivot.role,
      };
      this.$refs['edit-user'].show();
    },
    search(q) {
      // console.log('What arrives: ', q);
      this.q = q;
      this.fetch(1);
    },
    /* search() {
      console.log('What arrives: ', this.qSearch);
      this.$emit('search', this.qSearch);
    }, */
    destroy(id) {
      Swal.fire({
        title: this.$t('generic-str.r-sure'),
        text: this.$t('user.destroy'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('warnings.btn-y-delete'),
        cancelButtonText: this.$t('app.cancel'),
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          this.$store.dispatch('destroyUser', id).then((response) => {
            this.fetch();
          });
        }
      });
    },
    changePage(page) {
      /* this.page = page;
      this.$emit('change-page', this.page); */
      this.fetch(page);
    },
    converToUUID(str) {
      if (!str) return '';
      str = this.insertString(str, 8, '-');
      str = this.insertString(str, 13, '-');
      str = this.insertString(str, 18, '-');
      str = this.insertString(str, 23, '-');

      return str.toLowerCase();
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .pagination_row {
  display: none !important;
}

::v-deep {
  .table-responsive {
    overflow: visible;
  }
}
a {
  span {
    color: #000;
    font-size: 20px;
    line-height: 20px;
  }
}
</style>

<template>
  <table class="table" :class="{ busy }">
    <thead>
      <tr>
        <th v-for="(field, n) in fields" :key="n">{{ getFieldName(field) }}</th>
        <th v-if="$slots.actions"></th>
      </tr>
    </thead>
    <tbody v-if="!busy">
      <tr v-if="items.length === 0">
    <td :colspan="fields.length" class="text-center log-null">
      Não há dados encontrados!
    </td>
  </tr>
      <tr v-for="(item, n) in items" :key="n">
        <td v-for="(field, i) in fields" :key="i">
          <template v-if="field.type === Boolean">
            <span
              v-if="getItemData(item, field)"
              class="material-symbols-outlined text-yup"
            >
              done
            </span>
          </template>
          <template v-else>
            <div v-if="field.key === 'payload' && item.payload !== null">
              <template v-if="item.payload.new">
                <div class="row">
                  <div class="values old-value" v-if="item.payload.old">
                    <div class="val-wrapper" v-if="item.payload.old.name">&lt;&lt; <span>{{item.payload.old.name}}</span></div>
                    <div class="val-wrapper" v-if="item.payload.old.mobile_number">&lt;&lt; <span>{{item.payload.old.mobile_number | maskPhone}}</span></div>
                    <div class="val-wrapper" v-if="item.payload.old.email">&lt;&lt; <span>{{item.payload.old.email}}</span></div>
                    <div class="val-wrapper" v-if="item.payload.old.number">&lt;&lt; <span>{{item.payload.old.number}}</span></div>
                    <!-- Deal -->
                    <div class="val-wrapper" v-if="item.payload.old.title">&lt;&lt; <span>{{item.payload.old.title}}</span></div>
                    <div class="val-wrapper" v-if="item.payload.old.description">&lt;&lt; <span>{{item.payload.old.description}}</span></div>
                    <div class="val-wrapper" v-if="item.payload.old.due_date">&lt;&lt; <span>{{item.payload.old.due_date | formatDate24}}</span></div>
                    <!-- <div class="val-wrapper" v-if="item.payload.old.position">&lt;&lt; <span>{{item.payload.old.position}}</span></div> -->
                    <div class="val-wrapper" v-if="item.payload.old.priority">&lt;&lt; <span>{{item.payload.old.priority}}</span></div>
                    <div class="val-wrapper" v-if="item.payload.old.assign_to">&lt;&lt; <span>{{item.payload.old.assign_to}}</span></div>
                    <div class="val-wrapper" v-if="item.payload.old.contact_id">&lt;&lt; <span>{{item.payload.old.contact_id}}</span></div>
                    <div class="val-wrapper" v-if="item.payload.old.stage">&lt;&lt; <span>{{item.payload.old.stage}}</span></div>
                    <div class="val-wrapper" v-if="item.payload.old.offerings">&lt;&lt; <span>{{item.payload.old.offerings}}</span></div>
                  </div>
                  <div class="values new-value" v-if="item.payload.new">
                    <div class="val-wrapper" v-if="item.payload.new.name"> <span>{{item.payload.new.name}}</span> &gt;&gt;</div>
                    <div class="val-wrapper" v-if="item.payload.new.mobile_number"> <span>{{item.payload.new.mobile_number | maskPhone}}</span> &gt;&gt;</div>
                    <div class="val-wrapper" v-if="item.payload.new.email"> <span>{{item.payload.new.email}}</span> &gt;&gt;</div>
                    <div class="val-wrapper" v-if="item.payload.new.number"> <span>{{item.payload.new.number}}</span> &gt;&gt;</div>
                    <!-- Deal -->
                    <div class="val-wrapper" v-if="item.payload.new.title"> <span>{{item.payload.new.title}} &gt;&gt;</span></div>
                    <div class="val-wrapper" v-if="item.payload.new.description"> <span>{{item.payload.new.description}} &gt;&gt;</span></div>
                    <div class="val-wrapper" v-if="item.payload.new.due_date"> <span>{{item.payload.new.due_date | formatDate24}} &gt;&gt;</span></div>
                    <!-- <div class="val-wrapper" v-if="item.payload.new.position"> <span>{{item.payload.new.position}} &gt;&gt;</span></div> -->
                    <div class="val-wrapper" v-if="item.payload.new.priority"> <span>{{item.payload.new.priority}} &gt;&gt;</span></div>
                    <div class="val-wrapper" v-if="item.payload.new.assign_to"> <span>{{item.payload.new.assign_to}} &gt;&gt;</span></div>
                    <div class="val-wrapper" v-if="item.payload.new.contact_id"> <span>{{item.payload.new.contact_id}} &gt;&gt;</span></div>
                    <div class="val-wrapper" v-if="item.payload.new.stage"> <span>{{item.payload.new.stage}} &gt;&gt;</span></div>
                    <div class="val-wrapper" v-if="item.payload.new.done_at"> <span>{{item.payload.new.done_at | formatDate24}} &gt;&gt;</span></div>
                    <div class="val-wrapper" v-if="item.payload.new.offerings"> <span>{{item.payload.new.offerings}} &gt;&gt;</span></div>
                  </div>
                </div>
              </template>
              <template v-if="item.payload.task">
                <div class="values">
                  <div class="val-wrapper">{{$tc('app.title', 1)}}: <span>{{item.payload.task.title}}</span></div>
                  <div class="val-wrapper">{{$t('app.description')}}: <span>{{item.payload.task.description}}</span></div>
                  <div class="val-wrapper">Criado em: <span>{{item.payload.task.created_at | formatDate24}}</span></div>
                </div>
              </template>
              <template v-if="item.payload.message">
                <div class="values">
                  <div class="val-wrapper"><span>{{item.payload.message.content}}{{item.payload.message.date | formatDate24}}</span></div>
                </div>
              </template>
              <template v-if="item.operation === 'destroy'">
                <div class="values">
                  <div class="val-wrapper"><pre>{{JSON.stringify(item.payload.item, null, 2)}}</pre></div>
                </div>
              </template>
              <template v-if="item.tags === 'email'">
                <div class="row">
                  <div class="col values">
                    <div class="head-values">
                      <div class="val-wrapper">De: <span>{{item.payload.from}}</span></div>
                      <div class="val-wrapper">Para: <span>{{item.payload.message[0].to}}</span></div>
                      <div class="val-wrapper">Nome: <span>{{item.payload.message[0].from_name}}</span></div>
                      <div class="val-wrapper">Assunto: <span>{{item.payload.message[0].subject}}</span></div>
                      <div class="val-wrapper">Email resposta: <span>{{item.payload.message[0].reply_email}}</span></div>
                    </div>
                    <div class="body-values">
                      <p class="mb-0">Conteúdo: </p>
                      <div class="content-value" v-html="item.payload.body"></div>
                    </div>
                    <div class="info-values">
                      <div class="val-wrapper">Tipo: <span>{{item.payload.message[0].source}}</span></div>
                      <div class="val-wrapper">Status: <span>{{item.payload.message[0].status}}</span></div>
                      <div class="val-wrapper" v-if="item.payload.message[0].error_code">Código de erro: <span>{{item.payload.message[0].error_code}}</span></div>
                      <div class="val-wrapper" v-if="item.payload.message[0].error_message">Mensagem de erro: <span>{{item.payload.message[0].error_message}}</span></div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <div class="text-table" v-else-if="field.key === 'user' && item.user !== null">
              <p class="m-0">{{item.user.name}}</p>
            </div>
            <div v-else-if="field.key === 'account' && item.account !== null">
              <p class="m-0">{{item.account.name}}</p>
            </div>
            <div v-else-if="field.key === 'code'" >
              <span class="badge" :class="[item.code === 200 ? 'badge-success' : 'badge-urgent']">● {{item.code === 200 ? `${$t('app.success')}` : `${$t('app.error')}` }}</span>
            </div>
            <div v-else-if="field.key === 'tags'" >
              <p class="m-0 tags">{{item.tags}}</p>
            </div>
            <span v-else>{{ getItemData(item, field) }}</span>
          </template>
        </td>
        <td v-if="$slots.actions">
          <slot name="actions" v-bind="item"></slot>''
        </td>
        <td v-if="$route.name === 'AdminLogs'">
          <slot name="logAction" v-bind="item" :userId="item.user.id"></slot>
        </td>
      </tr>
    </tbody>
    <tbody v-else>
      <tr>
        <td :colspan="fields.length">
          <div class="text-center text-primary my-2">
            <span aria-hidden="true" class="align-middle spinner-border">
            </span>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'Table',
  props: {
    items: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
    },
    busy: {
      type: Boolean,
    },
  },
  data() {
    return {
      obj: false,
      objTest: [],
      months: this.$t('primevue.monthNames'),
      today: this.$t('primevue.today'),
    };
  },
  methods: {
    getFieldName(field) {
      let fieldName = '';
      if (field.label) {
        fieldName = field.label;
      } else {
        fieldName = this.$tc(`app.${field.key}`, 1);
      }
      return fieldName;
    },
    getItemKey(item) {
      return typeof item === 'string' ? item : item.key;
    },
    getItemData(item, field) {
      this.obj = false;
      const keys = this.getItemKey(field).split('.');

      let itemData = keys.reduce((obj, part) => (obj ? obj[part] : ''), item);

      if (field.localize) {
        itemData = this.$tc(`app.${itemData}`, 1);
      }
      if (field.type === 'date') {
        itemData = (new Date(itemData)).toISOString().split('T')[0];
      } else if (field.type === 'datetime') {
        const date = new Date(itemData);
        const dateString = date.toLocaleDateString();
        const timeString = date.toLocaleTimeString();
        itemData = `${dateString} ${timeString}`;
      }
      return itemData;
    },
  },
  filters: {
    maskPhone(phone) {
      if (!phone) return '';

      phone = phone.replace(/\D+/g, '').substring(0, 13);

      switch (phone.length) {
        case 13:
          phone = phone.replace(/^(\d{2})(\d{2})(\d{5})(\d{4})$/, '+$1 ($2) $3-$4');
          break;
        case 12:
          phone = phone.replace(/^(\d{2})(\d{2})(\d{4})(\d{4})$/, '+$1 ($2) $3-$4');
          break;
        case 11:
          phone = phone.replace(/^(\d{2})(\d{2})(\d{5})(\d{4})$/, '+55 ($2) $3-$4');
          break;
        case 10:
          phone = phone.replace(/^(\d{2})(\d{2})(\d{4})(\d{4})$/, '+55 ($2) $3-$4');
          break;
        default:
      }
      return phone;
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  border-collapse: separate;
  border-spacing: 0 10px;
  &.busy {
    opacity: 0.55;
  }
}

.table th,
.table thead th {
  border-bottom: 3px solid rgba(107, 35, 220, 0.3) !important;
}

td,
th {
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 118px;
  color: #595959;
  font-size: 14px;
  font-weight: 400;
}

.td-ellipsis:hover {
  overflow: visible; /* Exibe o texto completo quando o cursor está sobre a célula */
  white-space: normal; /* Permite que o texto quebre em várias linhas */
  z-index: 1; /* Garante que a célula sobreponha outras células */
  position: relative; /* Define a posição da célula como relativa */
    }

td {
  border: 1px solid #e5e5e5;
  border-style: solid none;
  max-width: 80px;
}

td:first-child {
  border-left-style: solid;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

td:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}
.text-primary {
  color: var(--clr-yup-purple) !important;
}

.values {
    display: flex;
    flex-direction: column;
    margin-top: 2px;
    // box-shadow: 0px 0px 1px 1px #dadada73;
    // background-color: #f3f3f352;
    .head-values, .body-values, .info-values {
      display: flex;
      gap: 10px;
    }
    .head-values, .body-values, .info-values {
      .val-wrapper {
        span {
          font-weight: 100;
        }
      }
    }
    .body-values {
      margin-bottom: .35rem;
      :first-child {
        font-weight: 400;
      }
    }
    .val-wrapper {
      margin-bottom: .35rem;
      font-weight: 400;
    }
    .val-wrapper:last-child {
      margin-bottom: 0;
    }
    &.old-value {
      margin-right: .5rem;
      .val-wrapper {
        color: var(--urgent-fg) !important;
      }
    }
    &.new-value {
      flex: 1 0 auto;
      .val-wrapper {
        text-align: end;
        color: var(--sent-fg) !important;
      }
    }
  }

  @media (max-width: 700px) {

.table-container table tbody tr {
width: 100%;
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: center;
text-align: center;
border: 1px solid #E5E5E5;
border-radius: 6px;
}

.table-container table tbody tr td {
  text-align: end;
}
table {
  thead {
    display: none;
  }

  td {
    text-align: right;
    position: relative;
    padding-left: 50%;
    border: none;
    width: 100% !important;
    box-sizing: border-box;
    &:before {
      content: attr(data-label);
      position: absolute;
      left: 16px;
      width: calc(50% - 16px);
      text-align: left;
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
}
</style>
